// core version + navigation, pagination modules:
import Swiper, { Navigation, Scrollbar, Pagination, Autoplay } from 'swiper'

// configure Swiper to use modules
Swiper.use([Navigation, Scrollbar, Pagination, Autoplay])

export default class Testimonials {
  constructor({ scroll, container, id }) {
    this.DOM = { el: document.getElementById(id) }
    this.scroll = scroll
    this.container = container

    this.init()
  }

  init() {
  }
}

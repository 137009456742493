import { ScrollSpin } from '../../Animations/ScrollSpin'

export default class TextGrid {
    constructor (id) {
        this.DOM = {el: document.getElementById(id)}
        this.DOM.svg = this.DOM.el.querySelector('.js-spinner')
        
        this.init()
    }

    init() {
        if (this.DOM.svg) this.spinner = ScrollSpin(this.DOM.svg)
    }
}
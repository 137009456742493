export default class Detection {
    constructor(screensize) {
      this.screenSize = screensize
    //   this.checkMobile = window.matchMedia('screen and (max-width: 575px)');
    //   this.checkTablet = window.matchMedia('screen and (min-width: 576px) and (max-width: 991px)');
    //   this.checkDesktop = window.matchMedia('screen and (min-width: 992px)');

    // min width
    this.checkMobile = 0 // 575
    this.checkTablet = 576 //992
    this.checkDesktop = 992 // 1440
    this.init()
    }

    init() {
        if (window.innerWidth < 575) {
            this.screenSize = 'mobile'
        } else if ( window.innerWidth > 576 && window.innerWidth < 991) {
            this.screenSize = 'tablet'
        } else if ( window.innerWidth > 992) {
            this.screenSize = 'desktop'
        } else {}
    }
  }
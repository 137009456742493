import { ScrollSpin } from '../../Animations/ScrollSpin'
import { BigTitle } from '../../Animations/BigTitle' 

export default class TitleAccreditations {
    constructor (id) {
        this.DOM = {el: document.getElementById(id)}
        this.DOM.svg = this.DOM.el.querySelector('.js-spinner')
        this.DOM.animatedTitle = this.DOM.el.querySelector('.js-title')
        
        this.init()
    }

    init() {
        if (this.DOM.svg) {
            this.spinner = ScrollSpin(this.DOM.svg)
        }
        if (this.DOM.animatedTitle) {
            this.title = BigTitle(this.DOM.animatedTitle)
        }
    }
}
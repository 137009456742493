import Accordion from "./Accordion";
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger)
import { halfHeight } from '../../Utils'

export default class FAQContent {
    constructor ({ scroll, container, screenSize, id }) {
        this.DOM = {el: document.getElementById(id)}
        this.scroll = scroll
        this.container = container
        this.screenSize = screenSize
        this.DOM.menuLinks = [...this.DOM.el.querySelectorAll('.js-link')]
        this.DOM.accordions = [...this.DOM.el.querySelectorAll('.js-accordion')];
   
       this.init()
    }

    scrollTo () {
        this.DOM.menuLinks.forEach(link => {
            const target = link.getAttribute('href')
            link.addEventListener('click', (e) => {
                e.preventDefault()
                // Scrolls you there
                document.querySelector(target).scrollIntoView({ block: "start", behavior: "smooth" })
            })
        })
    }

    init () {
        this.scrollTo()
        
        this.DOM.accordions.forEach( item => {
            this.accordion = new Accordion({
                scroll : this.scroll,
                container: this.container,
                id: this.DOM.el.id
            })
        })
    }
}


export default class HeroSearch {
  constructor() {
    this.DOM = { el: document.querySelector(".HeroSearch") }
    this.DOM.disclaimerSpan = this.DOM.el.querySelector(".js-disclaimer")
    this.DOM.form = this.DOM.el.querySelector(".js-search-form")
    this.DOM.input = this.DOM.form.querySelector(".js-search-field")
    this.DOM.loading = this.DOM.el.querySelector(".js-sr-loader")
    this.DOM.resultsDiv = this.DOM.el.querySelector(".js-render-results")
    this.DOM.resultsTitle = this.DOM.el.querySelector(".js-results-title")
    this.DOM.submit = this.DOM.form.querySelector(".js-search-submit")

    this.DOM.loadingCard = this.DOM.el.querySelector(".js-card")

    // Events
    this.updateValue = new CustomEvent('updateSearchValue', {
        bubbles: true,
        detail: { searchTerm: () => this.DOM.input.value }
    })

    // events
    this.searchInputTerm = this.searchTerm.bind(this)

    this.searchResults = {}
    this.emptyResults()
  }

  create() {
    this.addEventListeners()
  }
  
  emptyResults() {
    this.searchResults.Pages = []
    this.searchResults.Posts = []
    this.searchResults.Products = []
    this.searchResults.Careers = []
  }

  onResize() {}

  // Necessary to have, leave empty if not needed
  gutenberg() {}

  onLoad() {
    this.create()
  }


  handleError (err) {
    console.log(err, 'sorry no pages or posts') 
  }


  renderNoResults (term) {

    setTimeout( () => {
      this.DOM.loading.classList.remove('js-active')
      //Append to page

      // after loader has tranisitioned out

      setTimeout( () => {
        const title = document.createElement('h3')
        title.classList.add('renderNoResults')
        title.classList.add('approach')
        title.innerHTML = `Sorry no pages or posts match your search ${term}, please try again`
        this.DOM.resultsDiv.appendChild(title)
      }, 400)

  }, 2000)

  }

  newPageCard(page) {

  let title

    if ( page.subtype == 'page') {
      title = `<h4 class='approach subtype_posts_title'>${page.title}</h4>`
    } else {
      title = `<h5 class='approach subtype_posts_title'>${page.title}</h5>`
    }

    return `<a class="results-card" href="${page.url}">
              <div class="results-card__title-section">
                ${title}
              </div>
            </a>`
  }

  renderSearchResults (results, term) {
 
    const keys = Object.keys(results)
    const resultDivEntries = []

    // iterate over object
    keys.forEach((key, index) => {

      // create title
      if (results[key].length)  {
        const pageSection = document.createElement('div')
        pageSection.classList.add('search_results__section')
        const title = document.createElement('h4')
        title.classList.add('results-card-title')
        title.innerHTML = `${key} that match '${term}'`
        pageSection.appendChild(title)      

        // then interiate over each key
        results[key].forEach(value => {
          const page = document.createElement('article')
          page.classList.add('result-card')
          page.innerHTML = this.newPageCard(value)
          pageSection.appendChild(page)
        })

        resultDivEntries.push(pageSection)
      }

    })

    // wait then allow results to be shown and hide loading state
    setTimeout( () => {
      this.DOM.loading.classList.remove('js-active')
      //Append to page

      // after loader has tranisitioned out
      setTimeout( () => {
      resultDivEntries.forEach(div => {
        this.DOM.resultsDiv.appendChild(div)
      })
    }, 400)

  }, 2000)

  }

  async searchTerm (e) {
    e.preventDefault()
    // grab current search term
    const term = this.updateValue.detail.searchTerm()

    // dont run if already search the term
    if (term === this.term) return        
    
    // save term to compare next time
    this.term = term


    // add loading state
    this.DOM.loading.classList.add('js-active')
    this.DOM.disclaimerSpan.classList.add('visually-hidden')

    // grabs all search responses
    const response = await fetch(`/wp-json/wp/v2/search?per_page=99&search=${term}`).catch(this.handleError)
    const data = await response.json()

    // empties results div after each search
    this.emptyResults()

    if (data.length > 0) {

      // Results Block Title
      this.DOM.resultsTitle.innerHTML = `<h2>Showing results for: '${term}'</h2>`

      // get acf data for each post
      data.forEach(item => {
        switch (item.subtype) {
          case 'page':
            let page = item
            this.searchResults.Pages.push(page)  
            break
          case 'post':
            let post = item
            this.searchResults.Posts.push(post)
            break                
          case 'product':
            let product = item
            this.searchResults.Products.push(product)  
            break
          case 'careers':
            let car = item
            this.searchResults.Careers.push(car)
            break
        }
        return this.searchResults
        // Do not search testimonial
      })

      this.renderSearchResults(this.searchResults, term)
    
    } else {
      this.renderNoResults(term)
    }

        window.history.replaceState(null, document.title, "/?s=" + term)

  }

  addEventListeners() {
    // The form element listens for the custom "updateSearchValue" event and then consoles the output of the passed text() method
    this.DOM.form.addEventListener('updateSearchValue', (e) => {
        // if we have a vlaue in input field add a space before text
        e.detail.searchTerm() ? this.DOM.disclaimerSpan.textContent = "Hit enter to search for: " + e.detail.searchTerm() : this.DOM.disclaimerSpan.textContent = ''
    })

    // listen for text changes on input field, when 'input' dispatch custom event
    this.DOM.input.addEventListener('input', (e) => {
        // clear results
        this.DOM.resultsDiv.innerHTML = ''
        this.DOM.resultsTitle.innerHTML = ''
        this.DOM.disclaimerSpan.classList.remove('visually-hidden')
        // dispatch event
        e.target.dispatchEvent(this.updateValue)
    })

    this.DOM.form.addEventListener('submit', this.searchInputTerm)
  }
}

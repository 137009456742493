import imagesLoaded from 'imagesloaded'
import EventEmitter from '../Classes/EventEmitter'
import gsap from "gsap";

import { GSDevTools, DrawSVGPlugin } from 'gsap/all'
gsap.registerPlugin(GSDevTools, DrawSVGPlugin)

export default class Preloader extends EventEmitter {
  constructor() {
    super()
    this.DOM = { el: document.querySelector('.js-preloader') }
    this.DOM.hidePage = document.querySelector('.hide-whole-page')
    this.DOM.images = [...document.querySelectorAll('img')]
    this.DOM.progressCircle = this.DOM.el.querySelector('circle')
   // this.DOM.numberText = this.DOM.el.querySelector('.preloader__number__text')
    this.length = 0
    gsap.set(this.DOM.progressCircle, {drawSVG: '0%'})
    this.imageLoader()
  }

  imageLoader() {
    const imgLoad = imagesLoaded(this.DOM.images)
    imgLoad.on('progress', (instance, img) => {
      this.onImagesLoaded(instance, img)
    })
  }

  onImagesLoaded(instance, img) {
    //   console.log( 'this image is loaded', img)
    // this.length += 1
    // const percent = this.length / this.DOM.images.length
    // this.DOM.numberText.innerHTML = `${Math.round(percent * 100)}%`
    // if (percent === 1) {
    //   // after we have loaded all the images wait 2 seconds before removing preloader
    //   setTimeout(() => {
    //     this.emit('completed', 'yes')
    //   }, 3000)
    // }

    this.loaderTimeline = gsap.timeline({
      defaults: {
        ease: 'expo.out',
        onComplete: () => {
          this.DOM.hidePage.classList.add('hide')
          this.emit('completed', 'yes')
        }
      },
      delay: 0.5
    })

    this.loaderTimeline
    .to(this.DOM.numberText, {
      innerText: 100,
      duration: 3.5,
      snap: 'innerText'
    })
    .to(this.DOM.progressCircle, { drawSVG: '100%', ease: 'expo.out', duration: 3.5 }, 0)

  }

  destroy() {
    this.DOM.el.classList.add('hidden__preloader')
    // setTimeout(() => {
    //   this.DOM.el.parentNode.removeChild(this.DOM.el)
    // }, 2000)
  }

  loop() {
    this.DOM.el.classList.remove('hidden__preloader')
    this.loaderTimeline.restart()
  }

  hide() {
    this.loaderTimeline.pause()
    this.DOM.el.classList.add('hidden__preloader')
  }
}

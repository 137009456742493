import OpenCareerModal from "../../Components/OpenCareerModal";

export default class CareersDisplay {
    constructor ({id, pageBackground, body}) {
        this.DOM = {el: document.getElementById(id)}
        this.body = body
        this.pageBackground = pageBackground
        this.DOM.posts = [...this.DOM.el.querySelectorAll('.js-career')];

        this.init()
    }

  onResize() {}

  gutenberg() {}

  init() {

    this.DOM.posts.forEach(item => {
        const vacancyPopup = new OpenCareerModal({
          id: item, 
          pageBackground: this.pageBackground,
          body: this.body
        })
    })
  }
}



import { ScrollSpin } from '../../Animations/ScrollSpin'
import { Parallax } from '../../Animations/Parallax'
import { gsap, GSDevTools } from "gsap/all"
gsap.registerPlugin(GSDevTools)

export default class CTAStats  {
    constructor (id) {
        this.DOM = {el: document.getElementById(id)}
        this.DOM.statWheel = this.DOM.el.querySelector('.js-stat-wheel')
        this.DOM.image = this.DOM.el.querySelector('.js-img')
        this.DOM.dial = this.DOM.el.querySelector('.js-dial-selector')
        this.DOM.spinner = this.DOM.el.querySelector('.js-spinner')
        this.DOM.texts = [...this.DOM.el.querySelectorAll('.js-CTAStats__info')]
        this.DOM.icons = [...this.DOM.el.querySelectorAll('.js-icon')]
        this.rotationSnap = 60

        this.texts = [...this.DOM.texts, this.DOM.texts[0]]
        this.init()
    }

    autoplayWheel() {
        // GSAP 3 Documentation
        // https://greensock.com/docs/v3/GSAP/gsap.to()

        this.timelines = []
        this.wheelTimeline = gsap.timeline({ repeat: -1 })
        
        // for each section
        // Paused on start so we can stich all the timelines into one timeline we repeat
        this.texts.forEach((text, index) => {
            // activate icon

            let animateSection = gsap.timeline()
            // Give oldindex any value so it isn't undefined on the first pass of the loop
            let oldIndex = 1
            index > 0 ? oldIndex = index - 1 : oldIndex

            // icon doesn't have a final array spot so if its transitioning to the last position, animate the first posiotn as these are the same
            let iconIndex
            index === this.DOM.icons.length ? iconIndex = 0 : iconIndex = index

            // ANIMATION Section
            // animate out old icons text
            // rotate dial
            // animate in current

            animateSection
                .to(this.DOM.icons[oldIndex], {color: '#28986F', duration: 0.2 })     
                .to(this.DOM.texts[oldIndex], {autoAlpha: 0, duration: 0.2}, '-=0.2')   
                .to(this.DOM.icons[iconIndex], { color: 'white', duration: 0.2 })
                .to(text, {autoAlpha: 1, duration: 0.2 }, '-=0.2')
                .to(this.DOM.dial, { rotation: this.rotationSnap * index, duration:3 })
              
            this.timelines.push(animateSection)
            
        })

        // merge all timelines
        this.timelines.forEach((timeline, index) => {
            let label = 'index_' + index
            this.wheelTimeline.add(timeline)
            this.wheelTimeline.addLabel(label)
        })
  
        // play combined timeline
        this.wheelTimeline.play()

        // GSDevTools.create({
        //     animation: this.wheelTimeline,
        //     id: 'wheel timeline'
        // })

    }

    clickIcon (e) {
        const target = e.currentTarget
        let positionIndex = this.DOM.icons.indexOf(target)

       // get position of dial. 
        // If dial is passed 180 deg and we click first icon
        // change index to this.text.length to animate to last position
        // otheriwse do nothing
        const currentDialPosition = gsap.getProperty(this.DOM.dial, "rotation")

       currentDialPosition > 180 && positionIndex === 0 ? positionIndex = this.texts.length : positionIndex

       // find index of target
       const moveTo = "index_" + positionIndex

       // move timeline to this location
       this.wheelTimeline.timeScale(6).tweenFromTo(this.wheelTimeline.time(), moveTo)
       this.wheelTimeline.pause()

    }


    pauseAnimation (e) {
        // const target = e.currentTarget
        // console.log('pause')
   
        // pause animation while mouseover
     //   this.wheelTimeline.pause()
        this.DOM.dial.classList.add('js-pause')

        // move to last point adn active
        const currentDialPosition = gsap.getProperty(this.DOM.dial, "rotation")
    
        // this returns the index that we should snap back to
        const moveTo = "index_" + Math.floor(currentDialPosition / this.rotationSnap)
      
       // You could tween from the current time of the timeline to the label with something like this:
          this.wheelTimeline.timeScale(3).tweenFromTo(this.wheelTimeline.time(), moveTo)
          this.wheelTimeline.pause()
    }

    continueAnimation (e) {
     //   console.log('continue')
        // play animation while mouseleave
        this.wheelTimeline.timeScale(1).play()
        this.DOM.dial.classList.remove('js-pause')
    }


    addEventListeners() {
        this.clickEvent = this.clickIcon.bind(this)
        this.pauseEvent = this.pauseAnimation.bind(this)
        this.playEvent = this.continueAnimation.bind(this)

        this.DOM.icons.forEach((icon) => {
            icon.addEventListener('click', this.clickEvent)
        })

        this.DOM.statWheel.addEventListener('mouseenter', this.pauseEvent)
        this.DOM.statWheel.addEventListener('mouseleave', this.playEvent)
    }

    init() {
        gsap.set(this.DOM.texts, {autoAlpha: 0})
        this.spinner = ScrollSpin(this.DOM.spinner)
        this.parallaxImage = Parallax(this.DOM.image)
        this.autoplayWheel()
        this.addEventListeners()
    }
}
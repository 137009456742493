import { SplitTitle } from '../../Animations/SplitTitle'
import { gsap } from 'gsap/all'

export default class HeroFullWidth {
  constructor() {
    this.DOM = { el: document.querySelector('.HeroFullWidth') }
    this.DOM.title = this.DOM.el.querySelector('.js-title')
    this.DOM.image = this.DOM.el.querySelector('.js-img')
  }

  create() {
    this.title = SplitTitle(this.DOM.title)
    gsap.set(this.DOM.image, { scale: 1.2 })

    // split text
    this.onResize()
  }

  animate() {
    this.tlHero = gsap.timeline({
      defaults: {
        ease: 'expo.out',
        duration: 0.8
      }
    })

    this.tlHero
      .to(this.title, {
        yPercent: 0,
        duration: 0.8,
        stagger: '0.2'
      })
      .to(this.DOM.image, { scale: 1, duration: 2, ease: 'power4.out' }, 0)
  }

  onResize() {}

  // Necessary to have, leave empty if not needed
  gutenberg() {}

  onLoad() {
    this.animate()
  }
}

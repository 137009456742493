import { SplitTitle } from '../../Animations/SplitTitle'
import { gsap, DrawSVGPlugin, SplitText } from 'gsap/all'
gsap.registerPlugin(DrawSVGPlugin, SplitText)

import Swiper, { Navigation } from 'swiper';

export default class ServicesSwiper {
  constructor() {
    this.DOM = { el: document.querySelector('.ServicesSwiper') }
    this.DOM.title = this.DOM.el.querySelector('.js-title')
    this.DOM.swiper = this.DOM.el.querySelector('.js-swiper')
    this.onLoad()
  }

  create() {
    this.title = SplitTitle(this.DOM.title)

    // split text
    this.onResize()
  }

  animate() {
    this.ssTitle = gsap.timeline({
      defaults: {
        ease: 'expo.out',
        duration: 0.8
      }
    })

    this.ssTitle
      .to(this.title, {
        yPercent: 0,
        duration: 0.8,
        stagger: '0.2'
      })


    var swiper = new Swiper(this.DOM.swiper, {
      slidesPerView: 1,
      spaceBetween: 10,
      rewind: true,
      speed: 500,
      navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
      },
      scrollbar: {
          el: ".swiper-scrollbar",
          hide: false,
          clickable: true,
      },
      breakpoints: {
        650: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1000: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    })

  }

  onResize() {}

  // Necessary to have, leave empty if not needed
  gutenberg() {}

  onLoad() {
    this.create()
    this.animate()
  }
}

// Hero
import HeroFullWidth from '../Blocks/Hero/HeroFullWidth'
import HeroVideo from '../Blocks/hero/HeroVideo'
import HeroSearch from "../Blocks/Hero/HeroSearch"
import HeroServices from "../Blocks/Hero/HeroServices"
import HeroSimple from '../Blocks/Hero/HeroSimple'
import HeroLanding from '../Blocks/Hero/HeroLanding'
import HeroContact from '../Blocks/Hero/HeroContact'

export default class HeroManager {
  constructor({ body, hero, header, screenSize, preloader, popup }) {
    this.hero = hero
    this.header = header
    this.screenSize = screenSize
    this.preloader = preloader
    this.popup = popup
    this.body = body

    if (this.hero) {
      this.currentHeroValue = this.hero.getAttribute("data-hero")
      this.init()
    } else {
      // If hero not available doing something, this.header.headerBackground()
    }
  }

  init() {
    this.heroCheck(this.currentHeroValue)
  }

  heroCheck(hero) {
    // split classnames into an array
    let heroClassnames = hero.split(" ")
    heroClassnames = heroClassnames.filter(
      (cl) => cl !== "alignfull" || !cl.includes("wp-block")
    )
    // flatten array
    this.currentHeroValue = heroClassnames[0]

    switch (this.currentHeroValue) {
      case 'HeroFullWidth':
        this.theHero = new HeroFullWidth()
        break
      case 'HeroVideo':
        this.theHero = new HeroVideo({
          body: this.body,
          header: this.header
        })
        break      
      case "HeroSearch":
        this.theHero = new HeroSearch()
        break
      case "HeroServices":
        this.theHero = new HeroServices()
        break
      case "HeroSimple":
        this.theHero = new HeroSimple()
        break
      case "HeroLanding":
        this.theHero = new HeroLanding()
        break
      case "HeroContact":
        this.theHero = new HeroContact()
        break
    }

    // if gutenberg
    if (typeof wp != "undefined" && wp.blockEditor) {
      if (this.theHero.gutenberg) {
        this.theHero.gutenberg()
      }
    } else {
      this.theHero.create()
    }
  }

  deleteHero() {
    if (this.theHero) {
      delete this.theHero
    }
  }

  // barba enter
  enterPageTransition() {
    this.currentHero = document.querySelector("[data-hero]")
    if (this.currentHero) {
      this.currentHeroValue = this.currentHero.getAttribute("data-hero")
    }

    this.heroCheck(this.currentHeroValue)
  }

  // does block need to enable gutenberg  back end editing
  gutenberg() {
    if (this.hero.gutenberg) {
      this.hero.gutenberg()
    }
  }

  onResize(screenSize) {
    this.screenSize = screenSize

    // console.log(this)
    //  console.log('heros resize')
  }
}
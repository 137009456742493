import gsap from 'gsap'

export default class Header {
  constructor() {
    this.DOM = { el: document.querySelector('header') }
    this.DOM.headerType = this.DOM.el.querySelector('.header-type')
    this.DOM.toggleIcon = this.DOM.el.querySelector('.menu_toggle')
    this.DOM.mobileMenu = this.DOM.el.querySelector('.mobile-sidebar')
    this.DOM.pageBackground = document.querySelector('.page-cover')
    this.DOM.fullScreenNav = this.DOM.el.querySelector('.fullscreen-nav-js')
    this.DOM.middleMenu = this.DOM.el.querySelector('#menu-middle-menu')
    this.DOM.mainMenu = this.DOM.el.querySelector('#menu-main-menu')
    this.DOM.mainMenuCanScroll = document.querySelector('.mobile-sidebar-can-scroll')
    this.DOM.middleShape = this.DOM.el.querySelector('.middle-shape')
    this.DOM.cartIcon = this.DOM.el.querySelector('.cartIcon__wrapper')
    this.DOM.cartIconNumb = this.DOM.el.querySelector('.cartIcon__number')
    this.DOM.revealActive = this.DOM.el.classList.contains(
      'header__activate_scrolling'
    )
    this.DOM.menuItem = [
      ...document.querySelectorAll('.child-menu-title')
    ]
    this.DOM.menuItemHasChildren = [
      ...document.querySelectorAll('.menu-item-has-children')
    ]
    this.DOM.subMenus = [...document.querySelectorAll('.sub-menu')]
    this.lastScroll = 0
    this.windowX = null
    this.winY = null

    // show header
    this.DOM.el.classList.add('js-appear')
    this.init()
  }

  toggleSubMenu(event) {
    const icon = event.currentTarget
    event.preventDefault()

    const currentSubMenu = icon.parentNode.querySelector('.sub-menu')

    if (this.DOM.headerType.classList.contains('logo_left')) {
      // Logo Left
    } else if (this.DOM.headerType.classList.contains('logo_middle')) {
      if (Array.isArray(this.DOM.subMenus)) {
        // close all other subMenus when selecting a new submenu
        this.DOM.subMenus.forEach((menu) => {
          menu.classList.remove('active-menu')
        })

        this.closeDropdowns()

        // on fullscreen with multiple dropdowns choose selected dropdowns
        if (icon.classList.contains('open-menu')) {
          icon.classList.remove('open-menu')
        } else {
          icon.classList.add('open-menu')
        }
      }
    } else {
    }

    // show or hide each menu
    if (icon.classList.contains('js-open')) {
      currentSubMenu.classList.remove('active-menu')
      icon.classList.remove('js-open')

      // on first click fullscreen nav menu with multiple submenus
    } else if (
      icon.classList.contains('open-menu') &&
      !icon.classList.contains('js-open')
    ) {
      currentSubMenu.classList.add('active-menu')
      icon.classList.add('js-open')
    } else if (
      !icon.classList.contains('js-open') &&
      this.DOM.headerType.classList.contains('logo_middle') &&
      Array.isArray(this.DOM.subMenus)
    ) {
      currentSubMenu.classList.remove('active-menu')
      icon.classList.remove('js-open')
    } else {
      currentSubMenu.classList.add('active-menu')
      icon.classList.add('js-open')
    }

    // Runs function to show that menu can be scrolled
    this.showMenuScroll()
  }

  init() {
    this.createDropdownIcon()
    this.changeColors()
    this.eventListeners()

    if (this.DOM.cartIconNumb.innerHTML != '0') {
      this.DOM.cartIcon.style.display = 'block';
    } else {
      this.DOM.cartIcon.style.display = 'none';
    }
  }
  
  // Compares sub menu height against window height and displays scrolling icon
  showMenuScroll() {
    // console.log('running show scroll box')
    const subMenuHeight = this.DOM.mainMenu.offsetHeight + 250

    if(subMenuHeight > window.innerHeight && this.DOM.mobileMenu.classList.contains('mobile-menu-appear')) {
      this.DOM.mainMenuCanScroll.classList.remove('can-scroll-hidden')
    } else {
      this.DOM.mainMenuCanScroll.classList.add('can-scroll-hidden')
    }
  }

  changeColors() {
    // this.DOM.el.classList.add('menu-state-white')

    if (this.DOM.mobileMenu.classList.contains('mobile-menu-appear')){
      this.DOM.el.classList.add('menu-state-white')
      this.DOM.el.classList.remove('menu-top-state')
    } else if (window.scrollY < 1){
      this.DOM.el.classList.remove('menu-state-white')
      this.DOM.el.classList.add('menu-top-state')
    } else {
      this.DOM.el.classList.add('menu-state-white')
      this.DOM.el.classList.remove('menu-top-state')
    }
  }

  createDropdownIcon() {
    if (this.DOM.menuItem) {
      if (Array.isArray(this.DOM.menuItem)) {
        // an array
        this.DOM.menuItem.forEach((item) => {
          // create a plus span
          const plus = `<button class='arrow-down' aria-label='Right Align'></button>`
          item.insertAdjacentHTML('afterend', plus)
        })
      } else {
        // a single element
        // create a plus span
        const plus = `<button class='arrow-down' aria-label='Right Align'></button>`
        this.DOM.menuItem.insertAdjacentHTML('afterend', plus)
      }
    }
  }

  onResize() {
    // this.removeCover()
    this.setMobileMenuHeight()
  }

  removeCover() {
    this.DOM.pageBackground.classList.remove('page-cover-opacity')
  }

  eventListeners() {
    if (this.DOM.toggleIcon) {
      this.DOM.toggleIcon.addEventListener('click', this.toggleMenu.bind(this))
    }

    window.addEventListener("scroll", this.changeColors.bind(this));

    if (this.DOM.menuItem) {
      if (Array.isArray(this.DOM.menuItem)) {
        // an array
        this.DOM.menuItemHasChildren.forEach((element) => {
          const icon = element.querySelector('.menu-link-arrow')
          if (icon) {
            icon.addEventListener('click', this.toggleSubMenu.bind(this))
          }
        })
      } else {
        // a single element
        this.DOM.menuItemHasChildren
          .querySelector('.menu-link-arrow')
          .addEventListener('click', this.toggleSubMenu.bind(this))
      }
    }
  }

  setMobileMenuHeight() {
    this.DOM.mobileMenu.style.height = window.innerHeight - this.DOM.el.offsetHeight + 1 + 'px'
  }

  toggleMenu() {
    this.setMobileMenuHeight()

    // Prevents scroll
    const body = document.querySelector('body')
    body.classList.toggle('main-stop-scroll')

    this.DOM.toggleIcon.classList.toggle('toggle_on')

    // IF we have logo - left header selected
    if (this.DOM.mobileMenu) {
      this.DOM.mobileMenu.classList.toggle('mobile-menu-appear')
      this.DOM.pageBackground.classList.toggle('page-cover-opacity')
      this.DOM.mainMenuCanScroll.classList.add('can-scroll-hidden')  
    }

    // Sets Header colours
    this.changeColors()
    this.showMenuScroll()
  }

  closeDropdowns() {
    const dropdwns = [...document.querySelectorAll('.menu-link-arrow')]
    dropdwns.forEach((toggle) => toggle.classList.remove('js-open'))
    this.DOM.mobileMenu.classList.remove('mobile-menu-appear')
    this.DOM.toggleIcon.classList.remove('toggle_on')
    this.DOM.pageBackground.classList.remove('page-cover-opacity')
  }
}
import Accordion from "./Accordion";
import { gsap, ScrollTrigger } from "gsap/all";


export default class FlexibleServices {
    constructor ({ scroll, container, id }) {
        this.DOM = {el: document.getElementById(id)}
        this.scroll = scroll
        this.container = container
        this.DOM.accordion = this.DOM.el.querySelector('.js-fs-accordion');

        this.init()
    }
    
    gutenberg() {
        if (window.acf) {
            this.init()
        }
    }
    init() {
    
        if (this.DOM.accordion) {
            this.accordion = new Accordion({
                scroll : this.scroll,
                container: this.container,
                id: this.DOM.el.id
            })
        }
     }
}
import ThreeTitles from '../../Animations/ThreeTitles'
import { ScrollSpin } from '../../Animations/ScrollSpin'
import Player from '@vimeo/player'
import gsap from 'gsap/all'

export default class HeroVideo {
  constructor({ body, header }) {
    this.DOM = { el: document.querySelector('.HeroVideo') }
    this.body = body
    this.header = header.DOM.el
    this.DOM.titles = [...this.DOM.el.querySelectorAll('.js-big-title')]
    this.DOM.recycling = this.DOM.el.querySelector('.js-spinner')
    this.DOM.textGroup = this.DOM.el.querySelector('.js-text-group')
    this.DOM.masterTextGroup = this.DOM.el.querySelector('.js-master-text-group')
    this.DOM.quoteSelector = this.DOM.el.querySelector('.js-quote-selector')
    this.body.callTab = this.body.querySelector('.js-coastal-tab')


    // preloader
    this.DOM.slider = this.DOM.el.querySelector('.js-slider')
    this.DOM.sliderLogo = this.DOM.el.querySelector('.js-slider-logo')

    // video
    this.DOM.videoLocation = this.DOM.el.querySelector('.js-video-iframe') 
    this.DOM.videoImage = this.DOM.el.querySelector('.js-hero-image') 

    this.videoOptions = {
      url: this.DOM.videoLocation.dataset.src,
      loop: true,
      controls: false
    }
  }

  create() {
   //  // Hide scroll ttt
   //  this.body.classList.add('main-stop-scroll')
    console.log('intro-')

    // Hide everything to Start
    // --------------------------------------------------------------
    // gsap.set(this.header, { yPercent: -100, opacity: 0 })
    gsap.set(this.DOM.sliderLogo, {autoAlpha: 0 })
    gsap.set(this.DOM.quoteSelector, { autoAlpha: 0, yPercent: 50 })
    if(this.DOM.videoImage) gsap.set(this.DOM.videoImage, {autoAlpha:1})
    this.DOM.textGroup.classList.remove('js-hide')
    this.DOM.sliderLogo.classList.remove('js-hide')

    // Hides call tab
    gsap.set(this.body.callTab, { xPercent: 100 })
   // --------------------------------------------------------------

   // SET UP TITLES
    this.titles = new ThreeTitles(this.DOM.titles)

    this.preloaderAnimation()
    this.onResize()

    if(this.videoOptions.url) {
      this.setupVideo()
    }
  }

  // Runs before video
  preloaderAnimation() {
    this.animationDone = false

  
    // STAGE 1
    // Animate In
    // --------------------------------------------------
    this.preloadAnimateIn = gsap.timeline({
      defaults: {
        ease: 'expo.out',
        duration: 0.8
      },
      delay: 1,
      paused: true,
      onStart: () => {
        // this runs infintitly so dont add to timeline
        gsap.to(this.DOM.sliderLogo, { rotation:"360", ease:'none', repeat:-1, duration:6 })

        if(this.videoOptions.url) {
          // Hides background image if video can play play
          this.player.play()
          console.log('g')

          this.player.on('play', () => {
            if(this.DOM.videoImage) gsap.to(this.DOM.videoImage, {autoAlpha:0})
          })
        }
      },
      onComplete: () => {
        // console.log('play')
        this.preloadAnimateOut.play()
      }
    })

    this.preloadAnimateIn
      .to(this.DOM.sliderLogo, { autoAlpha: 1, duration: 0.5})

   // add text animation
    this.preloadAnimateIn.add(this.titles.animateIn())

    // Play first animation
    this.preloadAnimateIn.play()

    // ------------------------------------------------------------------
                             
    // STAGE 2
    // Animate Out

    this.preloadAnimateOut = gsap.timeline({
        defaults: {
          ease: 'expo.out',
          duration: 0.8,
        },
        paused: true,
        onComplete: () => {
          this.animateAfterLoad()
          this.DOM.masterTextGroup.classList.add('js-index')
          this.animationDone = true          
        }
    })

    this.preloadAnimateOut
      .to(this.DOM.sliderLogo, { scale:.5, duration: 0.5, autoAlpha:0, ease: "back.inOut(4)"}, 0)
      .to(this.DOM.slider, { width: 0, duration: 0.5, ease: "power3.out" })


      // DESKTOP
      // move text over, otherwise remain
      gsap.matchMedia().add("(min-width: 1200px)", () => {
        this.resizedAnimation(50)
      })

      // FAIRLY LARGE
      gsap.matchMedia().add("(min-width: 1800px)", () => {
        this.resizedAnimation(80)     
      })

      // HUGE DESKTOP
      gsap.matchMedia().add("(min-width: 2200px)", () => {
          this.resizedAnimation(90)
      })

      this.preloadAnimateOut
        .to(this.header, {yPercent: 0, opacity: 1, duration: 0.4}, '-=0.4')
        .to(this.DOM.quoteSelector, { yPercent: 0, autoAlpha: 1, delay: 1})  

  }

  resizedAnimation(value) {
    this.preloadAnimateOut
      .to(this.DOM.textGroup, { xPercent: value, duration: 1, delay: 0.3 }, 0)

    if (this.animationDone) gsap.to(this.DOM.textGroup, { xPercent: value, duration: 1, delay: 0.3 }, 0)

  }

  setupVideo() {
    this.player = new Player(this.DOM.videoLocation, this.videoOptions)
    this.player.setVolume(0)
  }

  animateAfterLoad() {
    // Spin animation
    this.spinner = ScrollSpin(this.DOM.recycling)

    gsap.set(this.body.callTab, { xPercent: 0 })
        
    // Checks screen size & runs Big Title animation    
    gsap.matchMedia().add("(min-width: 992px)", () => {
      this.titles.animateOnPage()
    })
        
    // Removes preloader
    this.DOM.slider.remove()
    this.DOM.sliderLogo.remove()
    
    // enables scroll
    // this.body.classList.remove('main-stop-scroll')
  }

  onResize() {
  }

  gutenberg() {
    this.setupVideo() 
  }

  onLoad() {
    // scroll to top
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}

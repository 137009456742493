import { SplitTitle } from '../../Animations/SplitTitle'
import { gsap } from 'gsap/all'

export default class HeroContact {
  constructor() {
    this.DOM = { el: document.querySelector('.HeroContact') }
    this.DOM.title = this.DOM.el.querySelector('.js-title')
  }

  create() {
    this.title = SplitTitle(this.DOM.title)

    // split text
    this.onResize()
  }

  animate() {
    this.tlHero = gsap.timeline({
      defaults: {
        ease: 'expo.out',
        duration: 0.8
      }
    })

    this.tlHero
      .to(this.title, {
        yPercent: 0,
        duration: 0.8,
        stagger: '0.2'
      })
  }

  onResize() {}

  // Necessary to have, leave empty if not needed
  gutenberg() {}

  onLoad() {
    this.animate()
  }
}
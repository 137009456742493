import { gsap } from "gsap/all";

 // Big Title Animation
export const BigTitle = (item) => {
    const line1 = item.querySelector('.TitleLine-1')
    const line2 = item.querySelector('.TitleLine-2')
    const line3 = item.querySelector('.TitleLine-3')
    gsap.set(line1, {xPercent:10})
    gsap.set(line2, {xPercent:-10})
    if (line3) {
        gsap.set(line3, {xPercent:5})
        gsap.timeline({
            duration: 0.3,
            scrollTrigger: {
                trigger: line1,
            }
        })
        .to(line1, {xPercent: 0, duration: 2.5, ease: "power2.out"})
        .to(line2, {xPercent: 0, duration: 2.5, ease: "power2.out"}, "<")
        .to(line3, {xPercent: 0, duration: 2.5, ease: "power2.out"}, "<")
    } else {
        gsap.timeline({
            duration: 0.3,
            scrollTrigger: {
                trigger: line1,
            }
        })
        .to(line1, {xPercent: 0, duration: 2.5, ease: "power2.out"})
        .to(line2, {xPercent: 0, duration: 2.5, ease: "power2.out"}, "<")
    }
}
export default class PostCodeCheck {
    constructor() {
        this.DOM = { el: document.querySelector('.PostCode-section') }
        this.init()
    }

    // Necessary to have, leave empty if not needed
    gutenberg() {}

    init() {
        // Get JSON
        const wooClientKey = process.env.WOO_KEY;
        const wooClientSecret = process.env.WOO_SECRET;
        const wooUrl = '/wp-json/wc/v3/products/attributes/1/terms?';
        const domain = 'https://' + window.location.hostname;
        // Select form & listeners
        const $postCodeForm = document.querySelector('.js-wrapper');
        const $postCodeChecking = document.querySelector('.js-check');
        const $postCodeResult = document.querySelector('.js-no-result');
        const $postCodeSuccess = document.querySelector('.js-success');
        const $postCodeSuccessInner = document.querySelector('.js-success-inner');
        const $postCodeSubmit = document.querySelector('.js-postcode');
        const $pcButton = document.querySelector('.js-postcode-no-result');

        
        $postCodeSubmit.addEventListener('click', getAttributes);
        $pcButton.addEventListener('click', reload);
        
        function reload() {
            window.location.reload();
        }
        
        function basicAuth(key, secret) {
            let hash = btoa(key + ':' + secret);
            return "Basic " + hash;
        }
        
        // Check PostCodes & show/hide divs
        async function getAttributes() {
            $postCodeForm.style.display = 'none';
            $postCodeChecking.style.display = 'block';
        
            const $postCodeEntered = document.querySelector('.js-enter-postcode').value.toUpperCase();;
            // console.log('Checking: ' + $postCodeEntered);
            try {
                const response = await fetch(domain + wooUrl + process.env.WOO_TOKEN + '&per_page=100', {
                    headers: {
                        'Authorization': basicAuth(wooClientKey, wooClientSecret),
                        'Content-Type': 'application/json'
                    }
                });
                const responseReturn = response.json();
                const printLocation = () => {
                    responseReturn.then((a) => {
                        for (let i = 0; i < a.length; i++) {
                            // console.log(i, a[i].name);
                            if (a[i].name === $postCodeEntered) {
                                // console.log("Found!");
                                $postCodeSuccessInner.innerHTML = "<h2 class='green-title'>Good news! " + a[i].name + " is in our service area!</h2>";
                                $postCodeChecking.style.display = 'none';
                                $postCodeSuccess.style.display = 'block';
                                $postCodeResult.style.display = 'none';
                                break
                            } else {
                                // console.log("Not Found!");
                                $postCodeChecking.style.display = 'none';
                                $postCodeSuccess.style.display = 'none';
                                $postCodeResult.style.display = 'block';
                            }
                        }
                    });
                };
                printLocation();
            }
            catch (error) {
                console.log('Errors' + error);
            }
        }
    }
}
/*********************************
 * Our Gravity Form JS fucntions *
*********************************/
jQuery(document).ready(function($) {
    if ($('div.gform_wrapper').length != 0) {
        // Global disable previous dates
        gform.addFilter( 'gform_datepicker_options_pre_init', function( optionsObj, formId, fieldId ) {
            optionsObj.minDate = 0;
            optionsObj.maxDate = 720;
            return optionsObj;
        });

        // Event dates
        gform.addFilter( 'gform_datepicker_options_pre_init', function( optionsObj, formId, fieldId ) {
            if ( formId == 1 && fieldId == 41 ) {
                optionsObj.minDate = 0;
                optionsObj.maxDate = 1800;
                optionsObj.onClose = function (dateText, inst) {
                    jQuery('#input_1_42').datepicker('option', 'minDate', dateText).datepicker('setDate', dateText).datepicker('option', 'maxDate', 1800);
                };
            }
            return optionsObj;
        });

        jQuery(document).on('gform_confirmation_loaded', function(event, formId){
            // code to be trigger when confirmation page is loaded
            console.log('non')
        });
    }
});
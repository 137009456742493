import { ScrollSpin } from '../../Animations/ScrollSpin'
import { SplitTitle } from '../../Animations/SplitTitle'
import { gsap, DrawSVGPlugin, SplitText } from 'gsap/all'
gsap.registerPlugin(DrawSVGPlugin, SplitText)

export default class HeroServices {
  constructor() {
    this.DOM = { el: document.querySelector('.HeroServices') }
    this.DOM.title = this.DOM.el.querySelector('.js-hsv-title')
    this.DOM.desc = this.DOM.el.querySelector('.js-hsv-desc')
    this.DOM.image = this.DOM.el.querySelector('.js-hero-img')
    this.DOM.opportunitySvg = this.DOM.el.querySelector('.js-spinner')
  }

  create() {
    this.title = SplitTitle(this.DOM.title)
    if (this.DOM.image){
      gsap.set(this.DOM.image, { scale: 1.2 })
    }
    gsap.set([this.DOM.desc, this.DOM.button], { autoAlpha: 0 })

    // split text
    this.onResize()
  }

  animate() {
    this.tlHero = gsap.timeline({
      defaults: {
        ease: 'expo.out',
        duration: 0.8
      }
    })

    this.tlHero
      .to(this.title, {
        yPercent: 0,
        duration: 0.8,
        stagger: '0.2'
      })
      .to([this.DOM.desc, this.DOM.button], { autoAlpha: 1 })

      if (this.DOM.image) {
        gsap.to(this.DOM.image, { scale: 1, duration: 2, ease: 'power4.out' }, 0)
      }


      // Spin animation
      if (this.DOM.opportunitySvg){
        this.spinner = ScrollSpin(this.DOM.opportunitySvg)
      }
  }

  onResize() {}

  // Necessary to have, leave empty if not needed
  gutenberg() {}

  onLoad() {
    this.animate()
  }
}

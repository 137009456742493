import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger)


export default class Accordion {
    constructor ({ scroll, container, id }) {
        this.DOM = {el: document.getElementById(id)}
        this.scroll = scroll
        this.container = container
        this.DOM.items = [...this.DOM.el.querySelectorAll('.js-accordion')];

        this.init()
        console.log(this.DOM.items)
    }


    showList(event) {
        const item = event.target.closest('.js-accordion')
        const desc = item.querySelector('.js-accordion-desc')
        const icon = item.querySelector('.js-accordion-close')

        // Closed State: height, padding & cross
        if (desc.offsetHeight > 0) {
            desc.style.height = `0px`;
            item.classList.remove('js-accordion-open')
            icon.classList.remove('js-open')

        } else {
            // Open State: height, padding & cross
            desc.style.height = `${desc.scrollHeight}px`;
            item.classList.add('js-accordion-open')
            icon.classList.add('js-open')
        }
    }

    onResize () {}
    
    addEventListeners() {
        this.DOM.items.forEach(item => {
            item.addEventListener('click', this.showList.bind(this))
        })
    }
    gutenberg() {
        if (window.acf) {
            this.init()
        }
    }
    init() {
        this.addEventListeners()
        this.onResize()
     }
}
import { BigTitle } from '../Animations/BigTitle'
import gsap from 'gsap'

export default class Footer {
  constructor() {
    this.DOM = { el: document.querySelector('footer') }
    this.DOM.title = this.DOM.el.querySelector('.js-big-title')

    this.init()
  }
  

  init() {
    // Big Title animation
    this.bigTitle = BigTitle(this.DOM.title)
  }
}

import OpenCareerModal from "../Components/OpenCareerModal";

export default class Careers {
    constructor({ body, pageBackground }) {
        this.body = body
        this.pageBackground = pageBackground
        this.DOM = { el: document.querySelector('.js-careers-article') }
        this.init()
    }

    init() {
        // Apply Now pop up
        const vacancyPopup = new OpenCareerModal({
            id: this.DOM.el, 
            pageBackground: this.pageBackground,
            body: this.body
          })
    }
}



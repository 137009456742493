import { ScrollSpin } from '../../Animations/ScrollSpin'

export default class InfoGrid {
    constructor (id) {
        this.DOM = {el: document.getElementById(id)}
        this.DOM.svg = this.DOM.el.querySelector('.item-9 svg')
        // this.DOM.images = this.DOM.el.querySelectorAll('.js-img');
        if (this.DOM.svg != null) {
            this.spin()
        }
    }

    spin() {
        this.spinner = ScrollSpin(this.DOM.svg)

        // this.DOM.images.forEach((image) => {
        //     this.parallaxImage = Parallax(image)
        // })
    }
}
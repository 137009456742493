import Testimonials from '../Blocks/page/Testimonials'
import Gallery from '../Blocks/page/Gallery'
import Accordion from '../Blocks/page/Accordion'
import List from '../Blocks/page/List'
import FAQContent from '../Blocks/page/FAQContent'
import PostDisplay from '../Blocks/Post/PostDisplay'
import ContactSection from '../Blocks/page/ContactSection'
import ServicesSwiper from '../Blocks/page/ServicesSwiper'
import PostCodeCheck from '../Blocks/page/PostCodeCheck'
import CTAStats from '../Blocks/page/CTAStats'
import CTABanner from '../Blocks/page/CTABanner'
import InfoGrid from '../Blocks/page/InfoGrid'
import ConfidenceLogos from '../Blocks/page/ConfidenceLogos'
import FlexibleServices from '../Blocks/page/FlexibleServices'
import TitleAccreditations from '../Blocks/Page/TitleAccreditations'
import TextGrid from '../Blocks/Page/TextGrid'
import CareersDisplay from '../Blocks/Post/CareersDisplay'
import SocialValues from '../Blocks/Page/SocialValues'

// import ImageSection from 'Blocks/Page/ImageSection'

export default class BlockManager {
  constructor({ body, blocks, scroll, wrapper, screenSize, pageBackground }) {
    this.body = body,
    this.blocks = blocks
    this.scroll = scroll
    this.pageBackground = pageBackground
    this.screenSize = screenSize
    this.scrollWrapper = wrapper
    this.currentBlocks = []
    this.onFirstPageLoad()

    if (typeof wp != 'undefined' && wp.blockEditor) {
      this.gutenberg()
    }
  }

  onFirstPageLoad() {
    //  console.log(this.blocks)
    this.blocks.forEach((block) => {
      this.blockCheck(block)
    })
  }

  blockCheck(block) {
    if (!this.currentBlocks) {
      this.currentBlocks = []
    }

    const className = block.className
    const id = block.id
    
    // split classnames into an array
    let keys = className.split(' ')
    // filter out any classes that match 'alignfull' or contain 'wp-block'
    keys = keys.filter((cl) => cl !== 'alignfull' || !cl.includes('wp-block'))
    // flatten array
    keys = keys[0]

    switch (keys) {
      case 'PostDisplay':
        const pd = new PostDisplay(id)
        this.currentBlocks.push(pd)
        break
      case 'Testimonials':
        const t = new Testimonials({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id
        })
        this.currentBlocks.push(t)
        break
      case 'ContactSection':
        const cs = new ContactSection(id)
        this.currentBlocks.push(cs)
        break
      case 'Accordion':
        const a = new Accordion({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id
        })
        this.currentBlocks.push(a)
        break
      case 'List':
        const l = new List(id)
        this.currentBlocks.push(l)
        break
      case 'Gallery':
        const g = new Gallery(id)
        this.currentBlocks.push(g)
        break
      case 'FAQContent':
        const faq = new FAQContent({
          scroll: this.scroll,
          container: this.scrollWrapper,
          screenSize: this.screenSize,
          id: id
        })
        this.currentBlocks.push(faq)
        break
      case 'Timeline':
        const tl = new Timeline({
          scroll: this.scroll,
          container: this.scrollWrapper,
          screenSize: this.screenSize,
          id: id
        })
        this.currentBlocks.push(tl)
        break
      case 'ServicesSwiper':
        const ss = new ServicesSwiper(id)
        this.currentBlocks.push(ss)
        break
      case 'PostCodeCheck':
        const pc = new PostCodeCheck(id)
        this.currentBlocks.push(pc)
        break
      case 'CTAStats':
        const ctas = new CTAStats(id)
        this.currentBlocks.push(ctas)
        break
      case 'CTABanner':
        const ctab = new CTABanner(id)
        this.currentBlocks.push(ctab)
        break
      case 'InfoGrid':
        const ig = new InfoGrid(id)
        this.currentBlocks.push(ig)
        break
      case 'ConfidenceLogos':
        const cl = new ConfidenceLogos(id)
        this.currentBlocks.push(cl)
        break
      case 'FlexibleServices':
        const fs = new FlexibleServices({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id
        })
        this.currentBlocks.push(fs)
        break
      case 'TitleAccreditations':
        const ta = new TitleAccreditations(id)
        this.currentBlocks.push(ta)
        break
      case 'TextGrid':
        const tg = new TextGrid(id)
        this.currentBlocks.push(tg)
        break
      case 'SocialValues':
        const sv = new SocialValues(id)
        this.currentBlocks.push(sv)
        break
      case 'CareersDisplay':
        const cd = new CareersDisplay({
          body: this.body,
          id: id,
          pageBackground: this.pageBackground
        })
        this.currentBlocks.push(cd)
        break
    }
  }

  deleteBlocks() {
    if (this.currentBlocks) {
      delete this.currentBlocks
    }
  }

  enterPageTransition(next) {
    // init
    const blocksOnNewPage = [...document.querySelectorAll('[data-block]')]

    blocksOnNewPage.forEach((block) => {
      this.blockCheck(block)
    })
  }

  // does block need to enable gutenberg  back end editing
  gutenberg() {
    this.currentBlocks.forEach((block) => {
      if (block.gutenberg) block.gutenberg()
    })
  }

  onResize(screenSize) {
    this.screenSize = screenSize

    this.currentBlocks.forEach((block) => {
      if (block.onResize) {
        block.onResize(this.screenSize)
      }
    })
  }
}

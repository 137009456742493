import gsap from "gsap";

export default class SocialValues {
    constructor (id) {
        this.DOM = {el: document.getElementById(id)}
        this.DOM.items = [...this.DOM.el.querySelectorAll('.js-accordion')]
        this.DOM.firstImage = this.DOM.items[0].querySelector('.js-image');

        this.init()
    }

    showList(event) {        
        // Closes all items first
        this.closeOthers()
        const item = event.target.closest('.js-accordion')
        const image = item.querySelector('.js-image')
        const desc = item.querySelector('.js-accordion-desc')
        const icon = item.querySelector('.js-accordion-close')

        /* Opens selected item */

        // Show / Hide Image
        gsap.matchMedia().add("(min-width: 992px)", () => {
            gsap.to(image, {opacity: 1, yPercent: -50, duration: 1, ease: "back.out(0.5)"})
        })
        gsap.matchMedia().add("(max-width: 991px)", () => {
            gsap.to(image, {opacity: 1, yPercent: 0, duration: 1, ease: "back.out(0.5)"})
        })

        // Show accordion desc if closed
        if (desc.offsetHeight > 0) {
            desc.style.height = `0px`;
            desc.style.paddingTop = `0`;
            item.classList.remove('js-accordion-open')
            icon.classList.remove('js-open')

        } else {
            // Show accordion desc if open
            desc.style.height = `${desc.scrollHeight}px`;
            desc.style.paddingTop = `2rem`;
            item.classList.add('js-accordion-open')
            icon.classList.add('js-open')
        }
    }

    closeOthers() {
        this.DOM.items.forEach(item => {
            const image = item.querySelector('.js-image')
            const desc = item.querySelector('.js-accordion-desc')
            const icon = item.querySelector('.js-accordion-close')
            
            gsap.to(image, {opacity: 0, yPercent: 100, duration: 1, ease: "back.out(0.5)"})
            desc.style.height = `0px`;
            item.classList.remove('js-accordion-open')
            icon.classList.remove('js-open')
        })
    }

    onResize () {}
    
    onLoad () {
        // Shows the first Image
        gsap.matchMedia().add("(min-width: 992px)", () => {
            gsap.to(this.DOM.firstImage, {opacity: 1, yPercent: -50})
        })
        gsap.matchMedia().add("(max-width: 991px)", () => {
            gsap.to(this.DOM.firstImage, {opacity: 1, yPercent: 0})
        })        
    }
    
    addEventListeners() {
        this.DOM.items.forEach(item => {
            item.addEventListener('click', this.showList.bind(this))
        })        
    }
    gutenberg() {
        if (window.acf) {
            this.init()
        }
    }
    init() {
        this.addEventListeners()
        this.onResize()
        this.onLoad()
    }
}
import { gsap, SplitText, CustomEase } from "gsap/all";
gsap.registerPlugin(SplitText, CustomEase)

    // Media Queries
    // Desktop
    // gsap.matchMedia().add("(min-width: 992px)", () => {
    //     gsap.set(this.DOM.titles, { xPercent: 50 })
    //   })
  
    //   // Mobile
    //   gsap.matchMedia().add("(max-width: 991px)", () => {
    //     gsap.set(this.DOM.titles, { xPercent: 0 })
    //   })

export default class VideoTitle {
    // only an array 
    constructor(titles) {
        this.DOM = {titles: titles}
        this.titles = []
        this.init()
    } 

    init() {
        // splits text
        this.DOM.titles.forEach(title => {
            const newTitle =  new SplitText(title, {type: "lines", linesClass: "lineChild"})
            const innerTitle =  new SplitText(title, {type: "lines", linesClass: "lineParent"})
            return this.titles.push(newTitle.lines)
        })

        // set starter positions
        gsap.set(this.titles[0], { xPercent: -100, autoAlpha: 0}) // first title left
        gsap.set(this.titles[1], { xPercent: 100, autoAlpha: 0}) // middle right
        gsap.set(this.titles[2], { xPercent: -100, autoAlpha: 0})  // bottom title left
    }

    animateIn() {
        this.tlanimateIn = gsap.timeline({
            defaults: {
                ease: 'expo.out',
                duration: 1
            },
            delay: .75
            // paused: true
        })

        this.tlanimateIn
            .to(this.titles[0], { xPercent: 0  })
            .to(this.titles[0], { autoAlpha: 1, autoAlpha: 1, duration: 0.85 }, '-=95%')
            .to(this.titles[1], { xPercent: 0, stagger: 0.25 }, '-=50%')
            .to(this.titles[1], { stagger: 0.25, autoAlpha: 1, duration: 0.85 }, '-=95%')
            .to(this.titles[2], { xPercent: 0, stagger: 0.25}, '-=50%')
            .to(this.titles[2], { stagger: 0.25, autoAlpha: 1, duration: 0.85 }, '-=95%')

        return this.tlanimateIn
    }

    animateOnPage() {
        this.tlanimateOnPage = gsap.timeline({
            repeatDelay: 3,
            yoyo: true,
            repeat: -1,
            defaults: {
                ease: 'expo.inOut', 
                duration: 1.5
            }
        })

        // animte title left to right in a loop
        this.tlanimateOnPage
            .fromTo(this.DOM.titles[2], {xPercent: 0 }, {xPercent: 10, stagger: 0.25})
            .fromTo(this.DOM.titles[1], {xPercent: 0 }, {xPercent: 10, stagger: 0.25})
            .fromTo(this.DOM.titles[0], {xPercent: 0 }, {xPercent: 10, stagger: 0.25})
    }
}
import Header from './Components/Header'
import Footer from './Components/Footer'
import Preloader from './Components/Preloader'
import Modal from './Components/Modal'
import Cursor from './Components/Cursor'
import CookiePolicy from './Components/CookiePolicy'
import './Components/Woocommerce'
import './Components/GravityForm'
import './Components/Fade'

//Classes
import BlockManager from './Classes/BlockManager'
import HeroManager from './Classes/HeroManager'
import Detection from './Classes/Detection'

//Page
import Careers from './Page/Careers'

//Utils
//import { getMousePos } from 'Utils'

//Pages
//import Portfolio from 'pages/Our-Portfolio'

class App {
  constructor() {
    //this.gutenberg = wp.blockEditor
    if (typeof wp != 'undefined') {
      //variable is undefined or null
      //Checking if we are viewing on backend or not
      if (wp.blockEditor) {
        this.addEventListeners()
      } else {
        this.init()
      }
    } else {
      this.init()
    }
  }

  init() {
    this.consoleMessage()
    this.detection()
    this.createContent()
    this.createHero()
    this.createSettings()
    this.createBlocks()
    this.addEventListeners()
    this.onResize()
  }

  consoleMessage() {
    console.groupCollapsed(
      '%cThis website was developed by Dirty Martini Marketing',
      'color:white, background:black'
    )
    console.log(
      'For all your website needs, please contact us | https://dirty-martini.com'
    )
    console.log(
      'Website theme built by Maxwell Kirwin https://maxwellkirwin.co.uk'
    )
    console.log(
      'With further support + development by Charles Farrelly https://uk.linkedin.com/in/charles-farrelly'
    )
    console.groupEnd()
  }

  createSettings() {
    this.preloaderInView = document.querySelector('.pre_loader')
    this.modalInView = document.querySelector('#myModal')
    this.cursorOnPage = document.querySelector('.cursor')
    this.devTools = document.querySelector('.dev-tools')
    this.gdpr = document.querySelector('#gdpr-box')

    if (this.preloaderInView) {
      this.preloader = new Preloader()
      //  console.log(this.preloader._events)
      this.preloader.once('completed', this.onPreloaded.bind(this))
    } else {
      if (this.HeroManager.theHero) {
        this.HeroManager.theHero.onLoad()
      }
      if (this.gdpr) {
        this.cookiePolicy = new CookiePolicy()
      }
    }

    if (this.modalInView) {
      this.modal = new Modal({pageBackground: this.pageBackground})
    }
    if (this.cursorOnPage) {
      //Initialize custom cursor
      this.mouse = { x: 0, y: 0 }
      this.cursor = new Cursor({ mouse: this.mouse })
    }
  }

  createContent() {
    this.body = document.querySelector('body')
    //this.scrollWrapper = document.querySelector('.main-content-container')
    //this.smoothScroll = {}
    //this.scrolling = document.querySelector('.header__activate_scrolling')
    this.header = new Header()
    this.footer = new Footer()
    this.pageBackground = document.querySelector('.js-page-cover')
    this.content = document.querySelector('.main-content-container')
  }

  onPreloaded() {
    // console.log('run on preloadered')
    this.preloader.destroy()
    //run hero animations

    if (this.gdpr) {
      this.cookiePolicy = new CookiePolicy()
    }

    //check if there is a hero on the page
    if (this.HeroManager.theHero) {
      this.HeroManager.theHero.onLoad()
    }
  }

  checkforGutenberg() {
    const hero = document.querySelector('[data-hero]')
    // const block = document.querySelector('[data-block]')
    if (hero) {
      /*post-title-0 is ID of Post Title Textarea*/
      //Actual functions goes here
      this.blocksLoaded = true
      this.createHero()
      this.createBlocks()
    }
    if (this.blocksLoaded) {
      clearInterval(this.loadingTimer)
    }
  }

  runGutenberg() {
    this.consoleMessage()
    //console.log('this is the backend')
    //check for heros  & run them
    this.blocksLoaded = false
    this.loadingTimer = setInterval(this.checkforGutenberg.bind(this), 500)
  }

  createHero() {
    this.currentHero = document.querySelector('[data-hero]')
    this.preloaderInView = document.querySelector('.pre_loader')

    this.HeroManager = new HeroManager({
      body: this.body,
      hero: this.currentHero,
      header: this.header,
      screenSize: this.screenSize,
      preloader: this.preloaderInView
    })

    if (this.currentHero === null || this.body.classList.contains('woocommerce-page')) {
      this.content.style.paddingTop = header.offsetHeight + "px";
    }
  }

  createBlocks() {
    this.blocks = [...document.querySelectorAll('[data-block]')]
    this.blocksOnPage = []

    //Check this if you want to create a new JS Block
    this.BlockManager = new BlockManager({
      body: this.body,
      pageBackground: this.pageBackground,
      blocks: this.blocks,
      //scroll: this.smoothScroll,
      //wrapper: this.scrollWrapper,
      screenSize: this.screenSize,
    })

    //if no blocks on the page, run createPages
    //if (!this.blocksOnPage.length) {
    if (!this.blocks.length) {
      this.createPages()
    }
  }
  createPages() {
    this.pages = {}
    //add a if statement for each page
    if (this.body.dataset.type === 'careers') {
      this.Careers = new Careers({
        body: this.body,
        pageBackground: this.pageBackground
      })
    } else {
      // 'no pages here'
    }
  }

  detection() {
    this.screenSize = ''
    this.detection = new Detection(this.screenSize)
    this.screenSize = this.detection.screenSize
  }

  onResize() {
    //re calculate screen size
    this.detection.init()
    this.screenSize = this.detection.screenSize

    this.header.onResize()

    //if (this.mobile) {
    //    console.log('mobile')
    //    this.ImageSection = new ImageSection.widthChange()
    //}

    //when we have smooth scroll enabled
    //_.isEmpty(this.smoothScroll)
    //this.smoothScroll ? '' : ScrollTrigger.addEventListener('refresh', () => this.smoothScroll.scroll.update())
    
    // if (this.HeroManager.theHero) this.HeroManager.theHero.onResize()

    // //run on Resize for all blocks
    // if (this.BlockManager) this.BlockManager.onResize(this.screenSize)

  }

  addEventListeners() {
    this.resizeEvent = this.onResize.bind(this)


    // Check for gutenberg
    if (typeof wp != 'undefined') {
      if (wp.blockEditor) {
        //GUTENBERG
        window._wpLoadBlockEditor.then(this.runGutenberg.bind(this))
        return
      }
    }

    //check for frontend
    if (this.cursorOnPage) {
      //Mouse effects on all links and others
      ;[...document.querySelectorAll('a')].forEach((link) => {
        link.addEventListener('mouseenter', () => this.cursor.enter())
        link.addEventListener('mouseleave', () => this.cursor.leave())
      })
    }

    window.addEventListener('resize',  this.resizeEvent)

    // if (this.scrolling) {
    // this.headerEvent = this.header.scrolling.bind(this)
    //   window.addEventListener('scroll', this.headerEvent)
    // }

    if (this.devTools) {
      const gridSelector = this.devTools.querySelector('#griddevtools')
      const grid = document.querySelector('.dt-grid')
      const darkmode = this.devTools.querySelector('#darkmodedevtools')
      const preloaderSelector = this.devTools.querySelector('#preloaderdevtools')
      const modalSelector = this.devTools.querySelector('#modaldevtools')

      gridSelector.addEventListener('change', (event) => {
        if (event.currentTarget.checked) {
          grid.classList.add('on')
        } else {
          grid.classList.remove('on')
        }
      })

      darkmode.addEventListener('change', (event) => {
        this.body.classList.toggle('dark-mode')
      })

    if (preloaderSelector) {
      preloaderSelector.addEventListener('change', () => {
        preloaderSelector.checked
          ? this.preloader.loop()
          : this.preloader.hide()
      })
    }

    if (modalSelector) {
      modalSelector.addEventListener('change', () => {
        modalSelector.checked
          ? this.modal.showModal()
          : this.modal.hideModal()
      })
    }
    }

  }
}

const website = new App()

/* Button with class: js-enquire will make an apply contact form visible */
import gsap from "gsap/all";

export default class OpenCareerModal {
  constructor ({ id, pageBackground, body }) {
    this.pageBackground = pageBackground
    this.body = body
    this.vacancy = {id: id}
    this.vacancy.openBtn = this.vacancy.id.querySelector('.js-enquire')
    this.vacancy.form = this.vacancy.id.querySelector('.js-apply-form')
    this.vacancy.numberID = this.vacancy.form.dataset.id
    this.vacancy.hash = `openModal=${this.vacancy.numberID}`
    this.vacancy.closeBtn = this.vacancy.id.querySelector('.js-close-form')

    gsap.set(this.vacancy.form, { yPercent: 20})

    this.addEventListeners()
  }

  openModal() {
    // add hash to url
    gsap.to(this.vacancy.form, {
        yPercent: 0,
        zIndex: 999,
        ease: "expo.out",
        duration: 0.4,
        autoAlpha: 1,
        onEnter: () => {
          this.pageBackground.classList.add("page-cover-opacity")
        },
        onComplete: () => {
          // this.vacancy.form.classList.remove("js-hide-modal")
          this.body.classList.add('main-stop-scroll')
          gsap.to(header, {y: -200, duration: 0.001})
        },
    })
  }

  closeModal() {
    gsap.to(this.vacancy.form, {
      yPercent: 20,
      ease: "expo.in",
      duration: 0.4,
      autoAlpha: 0,
      onComplete: () => {
        this.vacancy.form.classList.add("js-hide-modal")
        this.pageBackground.classList.remove("page-cover-opacity")
        this.body.classList.remove('main-stop-scroll')
        gsap.to(header, {y: 0, duration: 0.001})
      },
    })
  }

  addEventListeners () {
    this.openEvent = this.openModal.bind(this)
    this.closeEvent = this.closeModal.bind(this)

    this.vacancy.openBtn.addEventListener('click', this.openEvent)
    this.vacancy.closeBtn.addEventListener('click', this.closeEvent)
  }
} 
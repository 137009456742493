import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

 // Makes it spin
export const ScrollSpin = (item) => {
  gsap.timeline({
    scrollTrigger:{
      trigger: 'body',
      scrub: 0.2,
      end:'+=10000',
    }
  })
  .to(item, {rotation:360*5, ease:'none'})
};
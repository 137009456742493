import Swiper, { Navigation, Pagination } from 'swiper';
import { Parallax } from '../../Animations/Parallax'

export default class CTABanner {
    constructor (id) {
        this.DOM = {el: document.getElementById(id)}
        this.DOM.swiper = this.DOM.el.querySelector('.js-swiper')
        this.DOM.image = this.DOM.el.querySelector('.js-img')
        this.init()
    }

    init() {
        const swiper = new Swiper(this.DOM.swiper, {
            freeMode: true,
            slidesPerView: 1,
            autoplay: {
              delay: 2500,
              disableOnInteraction: true,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        })
    }

    onResize() {
        this.init()
        this.parallaxImage = Parallax(this.DOM.image)
    }
}

